import React, { useContext, useState, useEffect } from 'react';
import { Modal } from 'chaser-components';
import { bool, oneOfType, string, func } from 'prop-types';
import { PaymentContext } from '../../../../../providers/paymentContext';
import ErrorMessage from './components/ErrorMessage/index';
import SuccessMessage from './components/SuccessMessage/index';
import usePush from '../../../../../hooks/usePush';
import getPaymentIntent from '../../../../../services/getPaymentIntent';
import useContact from '../../../../../hooks/useContact';
import useSelectedTotal from '../../../../../hooks/useSelectedTotal';
import { paymentProviders } from '../../../../../util/constants';

import style from './PaymentModal.module.scss';
import LoadingMessage from '../../../../../components/LoadingMessage';

const PaymentModal = ({ open, toggle }) => {
  const { setPaymentError, paymentOptions } = useContext(PaymentContext);
  const { paymentResult, payingInvoiceIds } = paymentOptions;
  const invoiceIds = Object.keys(payingInvoiceIds).length;
  const { invoices, currency } = useSelectedTotal(!!invoiceIds);
  const { orgId, contactId } = useContact();
  const [DoPayment, setDoPayment] = useState(false);

  const { response: shortLink, execute } = usePush(async () => {
    const invoicesMapped = invoices.map(i => ({
      id: i._id,
      amount: i.isPartial ? i.instalmentsTotal : i.AmountDue,
      CurrencyCode: i.CurrencyCode,
    }));

    if (!invoices || invoices.length === 0) return;

    const result = await getPaymentIntent({
      orgId,
      contactId,
      currency,
      invoices: invoicesMapped,
      provider: paymentProviders.unipaas,
      successfulPaymentRedirect: window.location.href,
    });

    return result.data.shortLink;
  });

  async function tryToPay() {
    setDoPayment(true);

    const [err] = await execute();

    if (err) {
      setPaymentError({
        message:
          err?.message || 'There was an issue processing your payment. Please contact support',
      });
    }
  }

  useEffect(() => {
    if (shortLink && DoPayment) {
      window.open(shortLink, '_self');
    }
  }, [shortLink, DoPayment]);

  useEffect(() => {
    setDoPayment(true);
    tryToPay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal size="full" open={open} toggle={toggle} disableClose className={style.background}>
      <div className={style.body}>
        <Modal.Body>
          <PaymentSteps error={!!paymentResult?.error} success={paymentResult?.success} />
        </Modal.Body>
      </div>
    </Modal>
  );
};

PaymentModal.propTypes = {
  open: bool,
  toggle: func,
};

function PaymentSteps({ error, success }) {
  if (error) return <ErrorMessage />;
  if (success) return <SuccessMessage />;
  return <LoadingMessage />;
}

PaymentSteps.propTypes = {
  error: oneOfType([bool, string]),
  success: bool,
};

export default PaymentModal;
